<template>
  <el-dialog title="VIP现金充值" :visible.sync="visible" @close="closeDialog" class="table" width="560px">
    <div class="qrcode-way">
      <div class="mpay-qrcode-container">
        <div ref="qrcodeContainer" class="qrcode-container"></div>
      </div>
      <div class="mpay-qrcode-tips">
        <p class="pay-info-container">
          充值金额:
          <span class="need-to-pay">￥{{nowprice}}</span>
          <span v-if="nowPreferential > 0">[赠送{{nowPreferential}}RM]</span>
        </p>
        <p class="qr-tip">{{payName}}扫码付款</p>
        <countdown
            v-on:end_callback="CountTimeOver"
            v-if="this.info.isFlag"
            :currentTime="Number(info.currentTime)"
            :startTime="Number(info.startDate)"
            :endTime="Number(info.endDate)"
            :tipText="'订单支付开始计时'"
            :tipTextEnd="'订单支付结束计时'"
            :endText="'订单已超时，请重新生成'"
            :minutesTxt="'分'"
            :secondsTxt="'秒'"
        ></countdown>
        <div class="warning">
          <p>提示：进行支付时，请勿刷新页面或关闭窗口，支付订单将保留3分钟，如您需要选择其他金额充值，请等待3分钟后再试。</p>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import {
  getVIPPayERCode,
  queryPayStatus,
  updatePayStatus
} from "../../../api/pay";
import { vipPaySuccess } from "../../../api/charge";
import QRCode from "qrcodejs2";
import { message } from '@/utils/resetMessage';
import countdown from '@/components/tempcount/countdown.vue';
export default {
  components:{
    countdown
  },
  props: ["dialogVisible", "curPreferential","currentPrice","dialogPaying","currentMonth","currentVipLevel"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val && !this.dialogPaying)  this.getVIPERCodeDatas();//this.doPaySuccess();//
      },
      deep: true
    },
    curPreferential(newValue){
      if(!this.dialogPaying){
        this.nowPreferential = newValue;
      }
    },
    currentPrice(newValue){
      if(!this.dialogPaying){
        this.nowprice = newValue;
      }
    },
    currentMonth(newValue){
      if(!this.dialogPaying){
        this.nowcurrentMonth = newValue;
      }
    }
  },
  data() {
    return {
      visible: false,
      nowprice: this.currentPrice,
      nowPreferential: this.curPreferential,
      nowcurrentMonth:this.currentMonth,
      payType: "wechat",
      payName: "微信",
      info:{
        isFlag:false,
        currentTime:'',
        startDate:'',
        endDate:''
      },
      currentOrderId:'', //当前订单ID
      timer: null  // 定时器名称
    };
  },
  beforeDestroy(){
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    //二维码
    showQRCode(url) {
      //先清空
      this.$refs.qrcodeContainer.innerHTML = "";
      //再生成二维码
      new QRCode(this.$refs.qrcodeContainer, {
        text: url,
        width: 160,
        height: 160,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
      });
    },
    getRandomCode(length){
      if (length > 0) {
        var data = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    getVIPERCodeDatas() {
      //获取二维码链接
      let dataForm = {
        order_type: this.payType,
        order_price: this.nowprice,
        order_name: this.getRandomCode(10),
        extension: "pptuku"
      };
      getVIPPayERCode(dataForm).then((result) => {
        this.$emit("onPay", true); // 创建订单的状态
        this.$nextTick(() => {
          this.showQRCode(result.qrUrl);
        });
        this.info.isFlag = true;
        this.info.startDate = this.info.currentTime = new Date().getTime();
        this.info.endDate = this.info.startDate + 60*3*1000;
        this.currentOrderId = result.orderId;
        this.getPayStatus();
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    getPayStatus(){ //定时查询状态
        if(this.currentOrderId!=null && this.currentOrderId != ''){
          let data1 = {
            orderId:this.currentOrderId
          }
          this.timer = setInterval(() =>{
            queryPayStatus(data1).then((result) => {
              console.log(JSON.stringify(result));
              if(result.payStatus == 1){ //支付成功
                this.doPaySuccess();
              }else if(result.payStatus == 3){ //过期
                this.doUpdatePayStatus();
                this.$emit("onPay", false); // 创建订单的状态
              }else{ //继续查询

              }
            }).catch((error) => {
              console.log(JSON.stringify(error));
            })
          },3000);
        }
    },
    doUpdatePayStatus() { //更新订单状态
      if (this.currentOrderId != null && this.currentOrderId != '') {
        let data2 = {
          orderId : this.currentOrderId,
          status:3
        }
        updatePayStatus(data2).then((result) => {
          console.log(JSON.stringify(result));
          this.$emit("closeDialog");
        }).catch((error) => {
          console.log(JSON.stringify(error));
        })
      }
    },
    doUpdatePayStatus_close() { //更新订单状态
      if (this.currentOrderId != null && this.currentOrderId != '') {
        let data2 = {
          orderId : this.currentOrderId,
          status:3
        }
        updatePayStatus(data2).then((result) => {
          clearInterval(this.timer);
          this.timer = null;
          this.$emit("closeDialog");
        }).catch((error) => {
          console.log(JSON.stringify(error));
        })
      }
    },
    doPaySuccess(){
      if (this.currentOrderId != null && this.currentOrderId != '') {
        let data3 = {
          orderId : this.currentOrderId,
          price: this.nowprice,
          otherPrice: this.nowPreferential,
          currentMonth:this.nowcurrentMonth,
          currentVipLevel:this.currentVipLevel,
        }
        vipPaySuccess(data3).then((result) => {
          this.$emit("onPay", false); // 创建订单的状态
          clearInterval(this.timer);
          this.timer = null;
          this.$emit("closeDialog");
          message.success({
            message: '您已充值成功，谢谢',
            duration: 4000,
            offset: 80
          })
        }).catch((error) => {
          this.$emit("onPay", false); // 创建订单的状态
          clearInterval(this.timer);
          this.timer = null;
          console.log(JSON.stringify(error));
        })
      }
    },
    closeDialog() {
      setTimeout(function (){
        this.$emit("closeDialog");
      }, 3000);
    },
    CountTimeOver(){ //倒计时结束触发
      this.$emit("onPay", false); // 创建订单的状态
      this.doUpdatePayStatus_close();
    }
  }
};
</script>

<style lang="scss" scoped>
.font-orange {
  font-size: 26px;
  color: #ffae00;
}
.qrcode-way {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .mpay-qrcode-container {
    position: relative;
    width: 160px;
    height: 160px;
    padding: 11px;
    text-align: center;
    background: url(../../../assets/images/pay/codeBox.png) no-repeat;
    background-size: contain;
  }
  .qrcode-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .mpay-qrcode-tips {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .pay-info-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    .need-to-pay {
      font-size: 24px;
      color: #fa2323;
      font-weight: 700;
      margin: 0 8px 0 2px;
    }
  }
  .qr-tip {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
  }
  .warning{
    display: block;
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
    p{
      font-size: 14px;
      color: #5e6d82;
      line-height: 1.5em;
    }
  }
}
</style>
